
























































import { Component, Vue } from 'vue-property-decorator';
import VButton from '@/components/VButton.vue';

@Component({
  components: { VButton },
})
export default class TrialPromo extends Vue {}
